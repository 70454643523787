<template>
  <div>
    <!-- tab栏 -->
    <!-- <van-tabs @click="onClickOnlyOne"> -->
    <!-- <van-tab title="社区活动">
        <div class="information">
          <div class="center"
            v-for="item in list"
            :key="item.index">
            <router-link :to="'/activityLog/detail/' + item.AId + '/1'">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block"
                  class="ContentA">
                  <img :src="item.AImg"
                    alt="" />
                </div>
                <div style="display: inline-block"
                  class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <div v-if="hidden"
            class="hidden">── 没有更多数据了 ──</div>
        </div>
      </van-tab> -->
    <!-- <van-tab title="党组活动"> -->
    <div class="information">
      <!-- 全部 -->
      <div class="center" v-for="item in lists" :key="item.index">
        <router-link :to="'/activityLog/detail/' + item.AId">
          <!-- <div class="centerTitle van-ellipsis">{{ item.Title }}</div> -->
          <div class="Content">
            <div style="display: inline-block" class="ContentA">
              <img
                v-if="item.AImg"
                style="border-radius: 5px"
                :src="item.AImg"
                alt=""
              />
              <img
                v-else
                style="border-radius: 5px; min-height: 85px"
                src="https://lyxnyapi.dexian.ren//upload/images/2022/7/d0e8c771-cd6.png"
              />
            </div>
            <div style="display: inline-block; width: 63%" class="ContentB">
              <div class="centerTitle van-ellipsis">
                {{ item.Title }}
              </div>
              <!-- <div>
                积分：{{ item.Score }}
              </div> -->
              <span
                class="centertime van-multi-ellipsis--l2"
                style="margin: 5px 0px; font-size: 13px; color: #666666"
                >{{ item.Outline }}</span
              >
            </div>
            <span
              class="centertime"
              style="float: right; margin-top: -15px; color: black"
              >{{ item.AuditTime }}</span
            >
          </div>
        </router-link>
      </div>
      <!-- 没有更多数据了 -->
      <div v-if="hidden" class="hidden">───── 没有更多数据了 ─────</div>
    </div>
    <!-- </van-tab> -->
    <!-- <van-tab title="志愿者活动">
        <div class="information">
          <div class="center"
            v-for="item in lists"
            :key="item.index">
            <router-link :to="'/activityLog/detail/' + item.AId + '/2'">
              <div class="centerTitle van-ellipsis">{{ item.Title }}</div>
              <div class="Content">
                <div style="display: inline-block"
                  class="ContentA">
                  <img :src="item.AImg"
                    alt="" />
                </div>
                <div style="display: inline-block"
                  class="ContentB">
                  <span class="van-multi-ellipsis--l3">{{ item.Outline }}</span>
                  <br />
                  <span class="centertime">{{ item.ADate }}</span>
                </div>
              </div>
            </router-link>
          </div>
          <div v-if="hidden"
            class="hidden">───── 没有更多数据了 ─────</div>
        </div>
      </van-tab> -->
    <!-- </van-tabs> -->
  </div>
</template>
<script>
import { setOpenId, getOpenId } from "@/utils/auth";
import {
  WxGetMySignupActivityPage,
  WeGetMySignupActivityPages,
} from "@/api/Activity";
import { Toast } from "vant";
export default {
  data() {
    return {
      akId: 0, //tab栏
      list: {}, //资讯列表
      lists: {},
      listfrom: {
        page: 1,
        limit: 10,
        // status: 0,
        akId: undefined,
        // akCate: 1,
        openID: this.$route.query["openid"],
      }, //查询传参
      hidden: true, //没有更多数据了
      listfroms: {
        page: 1,
        limit: 10,
        status: 0,
        openID: this.$route.query["openid"],
      },
    };
  },
  methods: {
    onClickOnlyOne(name, title) {
      console.log(name);
      if (name == "0") {
        this.list = [];
        this.listfrom.page = 1;
        this.listfrom.limit = 10;
        this.listfrom.status = 0;
        this.listfrom.akId = 0;
        this.listfrom.openID = this.$route.query["openid"];
        this.getList();
      }
      if (name == "1") {
        this.lists = [];
        this.listfroms.page = 1;
        this.listfroms.limit = 10;
        this.listfroms.status = 0;
        this.listfroms.openID = this.$route.query["openid"];
        this.getLists();
      }
      if (name == "2") {
        this.list = [];
        this.listfrom.page = 1;
        this.listfrom.limit = 10;
        this.listfrom.status = 0;
        this.listfrom.akId = 1;
        this.listfrom.openID = this.$route.query["openid"];
        this.getList();
      }
    },
    // 获取分页列表
    getList() {
      WxGetMySignupActivityPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.lists = {};
            } else {
              this.lists = res.data.data;
              for (var i = 0; i < this.lists.length; i++) {
                if (this.lists[i].AImg == "") {
                  this.lists[i].AImg = "https://img01.yzcdn.cn/vant/cat.jpeg";
                }
              }
            }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch(() => {});
    },
    getLists() {
      WeGetMySignupActivityPages(this.listfroms)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.lists = {};
            } else {
              this.lists = res.data.data;
              for (var i = 0; i < this.lists.length; i++) {
                if (this.lists[i].AImg == "") {
                  this.lists[i].AImg = "https://img01.yzcdn.cn/vant/cat.jpeg";
                }
              }
            }
          } else {
            Toast(res.data.msg);
          }
        })
        .catch(() => {});
    },
    // tan点击切换事件
    onClick(e) {
      this.akId = e;
      this.getList();
    },
  },
  mounted() {
    this.getList();
  },
};
</script>
<style>
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
/deep/ .van-uploader {
  vertical-align: middle;
}
</style>